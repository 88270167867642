<template>
  <div class="notifications">
    <ul v-if="notificationItems.length" class="notification-list">
      <li
        v-for="(item, index) in notificationItems"
        :class="['notification-item', { active: item.status }]"
        :key="index"
      >
        <div class="notification-status" />
        <div class="notification-detail">
          <div class="notification-content">
            <div
              class="notification-description"
              v-html="item.message"
              :ref="`notification_${index}`"
              @mousedown="handleLinkClick($event, item.notificationId)"
            />
            <div class="notification-date">
              {{ formatDate(item.createDate) }}
            </div>
            <div class="notification-bar" />
          </div>
        </div>
      </li>
    </ul>
    <VueText v-else sizeLevel="12" class="empty-message"
      >Herhangi bir bildiriminiz bulunmamaktadır.</VueText
    >
  </div>
</template>
<script>
import { ref, onMounted } from 'vue';

import { Notification } from '@/services/Api/index';
import VueText from '@/components/shared/VueText/VueText.vue';
import dateUtils from '@/mixins/dateUtils.js';

export default {
  name: 'Notifications',
  components: { VueText },
  mixins: [dateUtils],
  setup() {
    const notificationItems = ref([]);

    const handleLinkClick = async (event, notificationId) => {
      const target = event.target;

      if (target.tagName.toLowerCase() === 'a') {
        event.preventDefault();

        const response = { notificationId: notificationId };
        const linkUrl = target.getAttribute('href');

        const res = await Notification.getViewNotification(response);
        if (res?.data?.Data) {
          window.location.href = linkUrl;
        }
      }
    };

    const getUserNotification = async () => {
      const res = await Notification.getUserNotification();
      notificationItems.value = res?.data?.Data?.userNotification ?? [];
    };

    onMounted(() => {
      getUserNotification();
    });

    return {
      notificationItems,
      handleLinkClick,
    };
  },
};
</script>
<style scoped lang="scss">
.notifications {
  .notification-list {
    padding: 0;
    list-style-type: none;
    .notification-item {
      position: relative;
      display: block;
      width: 100%;
      border-radius: 5px;
      background-color: #fff;

      &.active {
        background-color: #fff7ed;
      }

      .notification-detail {
        display: flex;
        color: #23303d;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        margin-left: 34px;
        padding-top: 20px;
      }
      .notification-status {
        top: 35px;
        left: 14px;
        width: 7px;
        height: 7px;
        position: absolute;
        border-radius: 50%;
        background-color: #f1701a;
        transform: translateY(-50%);
      }
      .notification-content {
        width: 100%;
      }
      .notification-description {
        color: #23303d;
        font-size: 18px;
        font-style: normal;
        line-height: 150%;
      }
      .notification-date {
        color: #23303d;
        font-size: 12px;
        margin: 5px 0;
        opacity: 0.5;
        color: #3d464f;
      }
      .notification-bar {
        background: rgba(0, 0, 0, 0.1);
        margin: 34px 0 0 0;
        width: 100%;
        height: 1px;
      }
    }
  }
  .empty-message {
    text-align: center;
    margin-top: 75%;
  }
}
</style>
